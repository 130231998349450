
import Vue from "vue";
import { mapGetters } from "vuex";
import KeyboardEvents from "~/constants/keyboardEvents";
import { XMarkIcon } from "~/components/UI/icons";
import type {
  Department,
  TaxonomyItem,
} from "~/components/layout/header/components/Departments";

const MENU_DELAY = 150;

export default Vue.extend({
  name: "DepartmentsMenu",
  components: {
    XMarkIcon,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    currentDepartmentIndex: 0,
    subdepartmentListHeight: null as number | null,
    showDepartments: false,
    timeout: null as ReturnType<typeof setTimeout> | null,
  }),
  computed: {
    currentDepartment(): Department {
      return this.getDepartmentsHierarchy[this.currentDepartmentIndex];
    },
    getMenuHierarchy(): Department[] {
      return this.getDepartmentsHierarchy;
    },
    ...mapGetters("locale", ["getDepartmentsHierarchy"]),
    ...mapGetters("viewport", ["isDesktop"]),
  },
  watch: {
    show(val) {
      if (val) {
        this.timeout = setTimeout(() => {
          this.toggleDepartmentMenu(true);
        }, MENU_DELAY);
      } else {
        clearTimeout((this as any).timeout);
        this.toggleDepartmentMenu(false);
      }
    },
  },
  methods: {
    generateListKey(list: string[]): string {
      return list.join("--");
    },
    taxonomyName(taxonomyItem: TaxonomyItem): string {
      return taxonomyItem.name;
    },
    taxonomyPath(taxonomyItem: TaxonomyItem): string {
      const url = taxonomyItem.slug;
      return url.startsWith("/") ? url : `/${url}`;
    },
    linkSelected(): void {
      this.$emit("close");
    },
    toggleDepartmentMenu(val: boolean): void {
      if (val) {
        this.showDepartments = true;
        this.$emit("showOverlay", true);
      } else {
        this.showDepartments = false;
        this.$emit("close");
        this.$emit("showOverlay", false);
      }
    },
    keyboardUpdateCurrentDepartment(event: KeyboardEvent, index: number): void {
      if (event.key === KeyboardEvents.ENTER_KEY_CODE) {
        event.preventDefault();
        this.currentDepartmentIndex = index;
      }
    },
    updateCurrentDepartment(index: number): void {
      this.currentDepartmentIndex = index;
    },
    doNothing(): void {},
  },
});
